import React from 'react';
import Layout from '../../components/layout';
// import './styles.scss';

const PrivacyPolicy = () => {
  return (
    <Layout
      title={'Privacy Policy | Text Reader'}
      metaName={'description'}
      metaContent={'By using the service on textreader.ai, you signify your agreement to follow all the privacy policies set forth on this page.'}
      small
    >
      <div className="page-hero">
        <h1 className="page-title">Privacy Policy</h1>
        <h3 className="page-subtitle">
          By using the service on this site, you signify your agreement to follow all the privacy policies set forth on
          this page.
        </h3>
      </div>
      <p className="bold">Data Collected</p>
      <p>
        When using our service, our website may store your IP, country of origin, URLs, number of downloads and related
        statistics. We may also use different types of cookies to log your personal preferences and experiences to our
        service so that we can provide personalized services to you. A cookie does not imply that we can access your
        private data in your computer, and you can further change your web browser's setting to decline cookies.
      </p>
      <p className="bold">Data Used</p>
      <p>
        We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This
        does not include trusted third parties who assist us in operating our website, conducting our business, or
        servicing you, so long as those parties agree to keep this information confidential. We may also release your
        information when we believe release is appropriate to comply with the law, enforce our site policies, or protect
        ours or others rights, property, or safety.
      </p>
      <p className="bold">Changes to This Privacy Policy</p>
      <p>
        We may update our Privacy Policy from time to time. When the changes are made, we will post the revised Policies
        here.
      </p>
    </Layout>
  );
};

export default PrivacyPolicy;
